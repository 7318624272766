/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Nicosie"), React.createElement("p", null, "Rappelons d’abord que la vieille ville de Nicosie est coupée en deux depuis 1974 par un mur, appelé ligne verte. Il s’agit d’une zone démilitarisée contrôlée par l’ONU entre la ", React.createElement("a", {
    href: "https://www.booking.com/country/cy.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "République de Chypre"), ", membre de l’Union européenne, et la ", React.createElement("a", {
    href: "https://www.booking.com/region/cy/northern-cyprus.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "République turque de Chypre du Nord"), ", reconnue seulement par la Turquie."), React.createElement("p", null, "Pour se loger à Nicosie, vous avez donc trois possibilités :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/cy/nicosia/ledrasstreet.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "La rue Ledra"), " : principale rue commerçante de la vieille ville, elle s’achève au sud par la place de la Liberté."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/landmark/cy/cyprus-museum.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Centre-ville de Nicosie-Sud"), " : la gare routière marque le début de la partie moderne de la ville où la majorité des habitants préfère résider."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/city/cy/lefkosa-turk.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Nicosie-Nord"), " : on trouve quelques établissements autour de Büyük Han, mais par rapport au développement économique de la capitale chypriote, la partie turque paraît être une petite bourgade."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;

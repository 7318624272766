import React from "react"

const BookingLink = ({ url, label }) => (
  <p className="txt-center">
    <a href={url} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  </p>
)

export default BookingLink

import React from "react"

const AccommodationSection = ({ title, subcategory, accommodations, city }) => {
  if (accommodations.length === 0) return null

  if (subcategory === "Auberge de jeunesse") {
    return (
      <>
        <h3>
          {title} à {city}
        </h3>
        {accommodations.map((accommodation, index) => {
          if (index === 0) {
            // Render the first accommodation with review
            return (
              <div key={index}>
                <p>
                  <a
                    href={accommodation.properties.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {accommodation.properties.name}
                  </a>
                </p>
                <p>{accommodation.properties.review}</p>
              </div>
            )
          }
          return null // Do not render anything for subsequent accommodations here
        })}

        {/* Additional recommended hostels in a separate paragraph */}
        {accommodations.length > 1 && (
          <p>
            Nos lecteurs recommandent aussi{" "}
            {accommodations.length - 1 === 1
              ? "l’auberge de jeunesse suivante"
              : "les auberges de jeunesse suivantes"}{" "}
            :{" "}
            {accommodations.slice(1).map((accommodation, index) => (
              <span key={index}>
                <a
                  href={accommodation.properties.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {accommodation.properties.name}
                </a>
                {/* Add ', ' for all except the last two, and ' et ' between the last two */}
                {index < accommodations.length - 3 && ", "}
                {index === accommodations.length - 3 && " et "}
              </span>
            ))}
            .
          </p>
        )}
      </>
    )
  } else {
    return (
      <>
        <h3>
          {title} à {city}
        </h3>
        <ul>
          {accommodations.map((accommodation, index) => (
            <li key={index}>
              <a
                href={accommodation.properties.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {accommodation.properties.name}
              </a>{" "}
              {accommodation.properties.review &&
                `: ${accommodation.properties.review}`}
            </li>
          ))}
        </ul>
      </>
    )
  }
}

export default AccommodationSection
